/* eslint-disable */
import React from "react"
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import Layout from '../../components/theme/Layout'
import Footer from '../../components/theme/Footer'
import Header from '../../components/theme/Header'
import SmallImageHeader from '../../components/theme/SmallImageHeader'
import RenderBlock from '../../components/blocks/RenderBlock'
import ImageRightTextLeft from '../../components/blocks/ImageRightTextLeftBlock'
import ImageLeftTextRight from '../../components/blocks/ImageLeftTextRightBlock'
import fixImageUrl from "../../helpers/fixImageUrl";

/*import CovidImg from '../../assets/images/Royale2.jpg'
import Logo1 from '../../assets/images/logo1@2x.png'
import Logo2 from '../../assets/images/logo2@2x.png'
import Logo3 from '../../assets/images/logo3@2x.png'
import ImgRight from '../../assets/images/imgright@2x.png'
import Button from '../../components/common/Button'
import CovidDonation from "../../components/common/CovidDonation";*/
import EventTracker from "../../components/common/EventTracker";
import Offers from "../../components/common/Offers";

const AboutRoyale = ({ data }) => {

  // This is only for the Bianca response, remove after that
  const blocks = []
  const metas = data.defaultPage.meta
  const urls = data.defaultPage.urls
  for (let i = 2; i < data.defaultPage.blocks.length; i+=1) {
    blocks.push(data.defaultPage.blocks[i])
  }
  const updatedMessages = {
    title:'PURR-oudly partnered with Bianca Andreescu',
    description:'Bianca embodies all the same qualities that make us proud to be Canadian – strength, dedication, kindness, and sportsmanship, on and off the court. That’s why she’s the perfect brand ambassador for ROYALE<sup>®</sup>.<br><br><strong>Bianca and ROYALE<sup>®</sup>: PURR-oudly Canadian.</strong>'
  }
  urls.en = '/purr-oudlycanadian'
  urls.fr = '/fr/fierrrementcanadienne'
  metas.title = 'PURR-oudly partnered with Bianca Andreescu | ROYALE®'

  let image1 = fixImageUrl("/globalassets/promotion/bianca/bianca-2.jpg");
  let image1Text = [
    {
      "__typename": "HeaderBlock",
      "type": "h2",
      "title": "Bianca Andreescu<br>Career Highlights",
      "guid": "image1header"
    },
    {
      "__typename": "TextBlock",
      "text": "<ul><li>Reigning champion at the Canadian Open</li><li>First Canadian to win the title in 50 years</li><li>First Canadian tennis player to win a Grand Slam singles title</li><li>2019 US Open Champion</li><li>Highest-ranked Canadian in the history of the Women’s Tennis Association with a career-high ranking of No. 4</li><li>First player to win a Grand Slam singles title as  a teenager since 2006</li></ul>",
      "guid": "bianca1",
      "modification": "biancabullet"
    }
  ];

  let image2 = fixImageUrl("/globalassets/promotion/bianca/bianca-3.jpg");
  let image2Text = [
    {
      "__typename": "HeaderBlock",
      "type": "h2",
      "title": "Follow Bianca’s story on Instagram @biancaandreescu_",
      "guid": "image2header"
    },
    {
      "__typename": "LinkBlock",
      "title": "Follow Her Story",
      "link": "https://www.instagram.com/biancaandreescu_/",
      "modification": "",
      "guid": "image2button"
    }
  ];

  let image3 = fixImageUrl("/globalassets/proudly-plant.jpg");
  let image3Text = [
    {
      "__typename": "HeaderBlock",
      "type": "h2",
      "title": "A PURR-oudly Canadian Company",
      "guid": "image3header"
    },
    {
      "__typename": "TextBlock",
      "text": "We’re a Canadian company that makes quality products, trusted to help make everyday tasks a little easier for everyone who uses them. We love being part of a team that is passionate and diverse. We compete (and win) on a global level every day.",
      "guid": "image3text"
    }
  ];
  // end Bianca adjustment

  return (
    <Layout
      meta={metas}
      urls={urls}
      locale='en'
    >
      <Header
        urls={urls}
        locale='en'
      />

      { /*  data.defaultPage.title / data.defaultPage.description / data.defaultPage.subtitle */}
      <SmallImageHeader
        subtitle=''
        description={updatedMessages.description}
        title={updatedMessages.title}
        image={false}
        video="https://vimeo.com/428576835"
        autoplay
        alt={data.defaultPage.title}
        right
      />

      { /*  Bianca Extra Section */}
      <ImageRightTextLeft alt="Bianca Andreescu" image={image1} left={image1Text} modification="center fullimage" />
      <ImageLeftTextRight alt="Follow Bianca’s story on Instagram @biancaandreescu_" image={image2} right={image2Text} modification="biancablue center wider" />
      <ImageRightTextLeft alt="A PURR-oudly Canadian Company" image={image3} left={image3Text} caption="Faison — Operator, Toronto Plant" modification="center morepadding" />
      {/*<div className="flex-container pad-left pad-top">
        <div className="fourth">
          <h3>What we’re doing</h3>
          <div className="quote">
            <quote>
              “It’s important that as a community, we take care of our neighbours who are impacted.”
              <span> - Robert K. Irving, Co-CE0 of J.D. Irving, Limited</span>
            </quote>
          </div>

          <p>It is anticipated that there will be a 50% increase in the meals the Food Depot Alimentaire will need to provide across New Brunswick; with school closures and the cancellation of other youth programs, there is also a need to replace breakfast and other meal programs. In addition to increased demand, many food banks have lost their ability to raise funds. Thrift stores and other venues have had to close which has limited the opportunities. To help meet this pressing need J.D. Irving, Limited is donating $1 million to the Food Depot Alimentaire. The donation will go to New Brunswick’s network of 60 food banks across the province due to the financial hardships caused by the Coronavirus (COVID-19) outbreak.</p>
            <Button
              to='https://bit.ly/2wRIF6X'
              width='170px'
              css={css`outline ${tw`mb-4`}`}
              outline
              convert={false}
              external
            >
            Full Details
            </Button>
        </div>
        <div className="sixth">
          <img src={ImgRight} alt="" />
          <small>From Left to Right: Dale Hicks, president of Food Depot Alimentaire; Robert K. Irving, Co-CEO J.D. Irving, Limited</small>
        </div>
      </div>
      <div className="flex-container blue-section pad-both">
        <div className="half">
          <h3>Our COVID-19 Safety Measures</h3>
          <p>The Coronavirus (COVID-19) outbreak is creating a rapidly changing environment and Governments have recognized tissue production as an essential service during these challenging times. We are doing everything possible to ensure we keep producing the ROYALE<sup>®</sup> tissue products that Canadians need to keep their homes clean and healthy. The safety and well-being of our employees and communities remains our first priority. </p>
          <p>It’s for these reasons that we have put measures in place to do our best to protect one another – including work-from-home arrangements for some of our employees, and limiting the number of people at any given worksite to those team members required for the safe and reliable operation of our business.</p>
          <p>As well, employees across our business are respecting health and safety measures as outlined by our local public health agencies to prevent the spread of the COVID-19:</p>
        </div>
        <div className="half points">
          <h3>&nbsp;</h3>
          <ul>
            <li>There are new protocols regarding screening at site entrances. </li>
            <li>Virtual meetings are encouraged for employee teams and customers.</li>
            <li>We have stopped all international travel and all domestic air travel to limit potential exposure.</li>
            <li>We have significantly enhanced sanitization and disinfection protocols at our facilities.</li>
            <li>We have provided up to 3 paid emergency leave days for employees. This leave is available to help with family commitments such as managing alternate childcare arrangements during school closures or to care for sick or vulnerable family members during the Coronavirus (COVID-19) outbreak.</li>
            <li>We’ve introduced pay continuation for self-isolation due to Coronavirus (COVID-19).</li>
            <li>We have a senior leadership team dedicated to this effort 24/7 and are providing daily updates to our employees.</li>
          </ul>
        </div>
      </div>
      <div className="flex-container pad-both partners">
        <div className="half">
          <h3>Partners and affiliates</h3>
          <p>Our parent company, partners and affiliates are all implementing new measures to increase safety and help take care of our employees, neighbours and communities at this time. Click the logos to find out how.</p>
        </div>
        <div className="half flex-container">
          <a href="https://jdirving.com/HomePage.aspx?LangType=1033" rel="noopener noreferrer" target="_blank"><img src={Logo1} alt="Logo" /></a>
          <a href="https://www.midlandtransport.com/" rel="noopener noreferrer" target="_blank"><img src={Logo2} alt="Logo" /></a>
          <a href="https://cavendishfarms.com/en" rel="noopener noreferrer" target="_blank"><img src={Logo3} alt="Logo" /></a>
        </div>
      </div>

      <CovidDonation />*/}

      <RenderBlock blocks={blocks} />

      <EventTracker gtag={{'allow_custom_scripts': true,'send_to': 'DC-8521957/invmedia/tiger0+standard'}} />
      <img height="1" width="1" style={{"border-style":"none"}} alt="" src="https://insight.adsrvr.org/track/pxl/?adv=7c99unf&ct=0:14yinok&fmt=3" />
      <Offers />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
query {
  defaultPage(guid: { eq:"5c1c9570-0086-4a6f-ac11-7460dc6481ac"}, locale: {eq:"en"}) {
    title
    title_html
    subtitle
    description
    image_url
    blocks {
      __typename
      ...textBlockFragment
      ...imageLeftTextRightBlockFragment
      ...imageRightTextLeftBlockFragment
      ...videoBlockFragment
      ...imageBlockFragment
      ...threeColumnWithIconsBlockFragment
    }
    urls {
      en
      fr
      zh_hant
      zh_hans
    }
    meta {
      description
      keywords
      title
    }
  }
}
`

export default AboutRoyale
